.pagination a {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  border: 1px solid #dee2e6;
}
.pagination {
  float: right;
}

.pagination .active {
  background-color: #20a8d8;
}

.pagination .active a {
  color: #fff;
}
.pagination .disabled a {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.mitizoverlay {
  background: #0000000d !important;
}
/* .mitizwrapper {
  position: inherit !important;
} */

.custome_error > p {
  color: red;
}
.custome_error > input {
  border-color: #ff0000 !important;
}
.field {
  position: relative;
}
.required {
  position: absolute;
  right: 7px;
  top: 12px;
  color: red;
  font-size: 10px;
}

ul.issues {
  width: 100%;
  margin-bottom: 10px;
}
ul.issues > li {
  display: inline-block;
  float: left;
  width: 50%;
  padding: 5px;
}

.issues .custom-control-label {
  padding-top: 5px;
}

.button:disabled {
  color: #887d7d;
}

.issue-modal .custom-control-label {
  padding-top: 5px;
}

.card-body .meta-image {
  float: left;
  padding: 0px;
  margin: 0px 15px;
  border-radius: 0px !important;
}

.user-post:last-child .we-comet {
  border-bottom: 0px;
}

.post-control {
  /* display: none; */
  position: absolute;
  right: 30px;
  z-index: 1;
}
.user-post:hover .post-control {
  display: block;
}

.coment-area .ReactGridGallery {
  /* margin-left: 62px; */
  width: 100%;
}
.like svg:hover {
  color: #20a8d8;
  cursor: pointer;
}
.liked svg {
  color: #20a8d8 !important;
}

.loader-box {
  width: 250px;
  background-color: #cccccc;
  padding: 20px;
  border-radius: 5px;
}
.loader-txt {
  line-height: 50px;
  color: black;
}

.sidebar .nav-link {
  padding: 0.75rem 0rem;
}

.react-datepicker-wrapper {
  display: inline !important;
}

.collapse:nth-last-child(2) .post-border {
  border-bottom: none;
}
.collapse:nth-last-child(1) .post-border-without-commentbox {
  border-bottom: none;
}


.react-tagsinput-tag {
  background-color: #552e7e !important;
  color: #ffffff !important;
  border: 1px solid #e6e7e2 !important;
}
.react-tagsinput-input {
  min-width: 200px !important;
}

footer {
  z-index: 1 !important;
}

.tooth-image li{
  list-style-type: none !important;
  display: inline-block  !important;
  cursor: pointer;
}
.tooth-image{
  width:500px;
}

.step {
  list-style: none;
  margin: .2rem 0;
  width: 100%;
}

.step .step-item {
  flex: 1 1;
  margin-top: 0;
  min-height: 1rem;
  position: relative;
  text-align: center;
}

.step .step-item span:before {
  background: #e9384f;
  border-radius: 50%;
  content: "";
  display: block;
  height: .8rem;
  left: 50%;
  position: absolute;
  top: .2rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: .8rem;
  z-index: 1;
}

.step .step-item:not(:first-child):before {
  background: #e9384f;
  content: "";
  height: 2px;
  left: -50%;
  position: absolute;
  top: 9px;
  width: 100%;
}

.step .step-item.active~.step-item a:before, .step .step-item.active~.step-item span:before {
  background: #c5c5c5;
}
.was-validated .form-control:invalid, .form-control.is-invalid{
  background-image: none;
}
.whiteoverlay {
  background: #e6dbdb0d !important;
}


.select-lg .css-yk16xz-control{
  padding:3px !important;
}

.select-lg .css-1pahdxg-control{
  padding:3px !important;
}

.rbc-allday-cell .rbc-row-content {
  display : none !important;
}

.rbc-events-container .rbc-event{
  height : 0 !important;
}

.link-without-underline{
  text-decoration: none !important;
}

.react-dropdown-tree-select .dropdown{
  width:100%;  
}
.react-dropdown-tree-select .dropdown-content{
  width:100%; 
  overflow-y: scroll; 
  height: 300px;
}
.react-dropdown-tree-select .dropdown-trigger{
  width:100%;
}
.react-dropdown-tree-select .search{
  border-bottom: none !important;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after{
  content: none !important;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after{
  content: none !important;
}
/*Slots component style start*/
.label-text{
  position: relative;
  margin: 15px 0 20px;
  color: #b7c9cc;
  text-align: center;
}

.label-text:before{
  position:absolute;
  border-top:1px solid #b7c9cc;
  top: 50%;
  left: 0;
  margin-top: -.5px;
  width: 100%;
  content: "";
}

.line-style{
  margin-left:5px !important;
  margin-right:5px !important;
}

.span-text{
  position:relative;
  display: inline-block;
  padding: 0 30px;
  background: #fff;
}
/*Slots component style end*/

.sortable {  
  background-image: url("assets/images/sortable.gif");
  background-repeat: no-repeat;
  background-position: center left;
  padding-left:17px !important;
  color: #20a8d8;
  cursor: pointer;
}
.asc {  
  background-image: url("assets/images/asc.gif");
  background-repeat: no-repeat;
  background-position: center left;
  padding-left:17px !important;
  color: #20a8d8;
  cursor: pointer;
}
.desc {  
  background-image: url("assets/images/desc.gif");
  background-repeat: no-repeat;
  background-position: center left;
  padding-left:17px !important;
  color: #20a8d8;
  cursor: pointer;
}

/* .asc {
  background-image: url("../images/asc.gif");
} */
.sidebar{
  &  .scrollbar-container{
    overflow-y: scroll !important;
    overflow-anchor: none;
    

  }
}