@media (max-width: 767px) {
  .left-side-login p {
    font-size: 14px;
  }
  .banner-section {
    background-position: 88% 100%;
  }
  .banner-section {
    min-height: 320px;
    background-position: center right;
  }
  .banner-contant.banner-contant-home h2,
  .banner-contant.banner-contant-home h2 span {
    font-size: 22px !important;
    line-height: 28px;
    margin-bottom: 5px;
  }
  .disruption-section .disuption-images-box .img-fluid {
    position: static;
  }
  .icon-bounce li {
    width: calc(100% / 2) !important;
    margin: 15px 0;
  }
  .animate-dots {
    display: none !important;
  }
  .icon-bounce li {
    width: calc(100% / 7);
    padding: 0;
    float: left;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    height: 246px !important;
  }
  .disruption-box {
    display: table;
    height: 270px;
    border: 0.5px solid #ccc;
    width: 100%;
    position: relative;
  }
  .disruption-box h3 {
    font-family: "Lato", sans-serif;
    font-size: 19px;
    color: #333;
    font-weight: 300;
  }
  .disruption-box p {
    margin-bottom: 0px;
  }

  .title-part p {
    margin-bottom: 30px;
  }
  .icon-bounce img {
    margin-bottom: 0px;
  }
  .testimonials .carousel-control-next,
  .docter-treatment .carousel-control-next {
    right: 5px;
  }
  .testimonials .carousel-control-prev,
  .docter-treatment .carousel-control-prev {
    left: 5px;
  }
  .testimonials-text {
    width: 100%;
    margin-top: 20px;
  }
  .testimonials-text span {
    font-size: 20px;
    text-align: center;
  }
  .docter-treatment .carousel-item {
    min-height: 730px;
  }
  .banner-contant-home .banner-button {
    padding: 5px 20px;
    font-size: 16px;
    line-height: 19px;
    margin-right: 166px;
  }
  .banner-contant-home .banner-button {
    margin-top: 15px;
  }
  .banner-contant-home .banner-logo {
    margin-top: 25px;
  }
  .form-box .custom-submit {
    margin-bottom: 20px;
  }
  .about-us-banner,
  .contact-us-banner {
    padding: 100px 0 170px !important;
  }
  .inner-heading {
    font-weight: 700 !important;
    font-size: 40px;
    text-align: left;
  }
  .banner-section {
    margin-top: 70px;
  }
  .custom-description {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .video-link {
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .searching input[type="text"] {
  }
}

@media (min-width: 576px) {
  .banner-section {
    background-position: 88% 100%;
  }
  .banner-section {
    min-height: 320px;
    background-position: center right;
  }
}

@media (max-width: 992px) {
  .main-header .navbar-toggler {
    background: #2887bf;
    border: 1px solid #000;
  }
  .disruption-box {
    display: table;
    height: 270px;
    border: 0.5px solid #ccc;
    width: 100%;
    position: relative;
  }
  .disruption-section .disuption-images-box .img-fluid {
    position: static;
  }
  .main-header .navbar {
    height: auto;
  }
  .main-header .navbar-nav > li {
    padding: 0px 8px;
  }
  .custom-nav {
    border-top: 1px solid #101010;
  }
}
