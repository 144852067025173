/* header css start */

body {
  background-color: #fff;
  /* font-family: "Helvetica"; */
  font-family: "Lato", sans-serif;
}

@font-face {
  font-family: "Helvetica LTStd light";
  src: url(assets/font/HelveticaNeueLTStd-Lt.otf.otf);
}

@font-face {
  font-family: "Helvetica LTStd light";
  src: url(assets/font/HelveticaNeueLTStd-Lt.otf.otf);
}

/* header css start*/

.main-header .navbar {
  height: 82px;
  background: #fff;
  border-color: rgba(255, 255, 255, 0.7);
  border-radius: 0;
  margin: 0;
  z-index: 10;
  width: 100%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4) !important;
}

.custom-nav > li > a {
  color: #545454 !important;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.main-header .navbar-brand > img {
  width: 150px;
}

.main-header .navbar-nav > li {
  padding: 15px 25px;
}

/* header css end */

/* home page css start */

.banner-section {
  width: 100%;
  float: left;
  position: relative;
  padding: 15px 0 40px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 82px;
  background-image: url("assets/images/bannerWOwatermark.jpg");
}

.banner-contant-home .banner-button {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  border: 2px solid;
  border-radius: 20px;
  font-size: 22px;
  line-height: 30px;
  padding: 8px 80px;
  letter-spacing: 1px;
  color: #000 !important;
}

.banner-contant-home .banner-button {
  margin-top: 50px;
}

.banner-contant-home .banner-logo {
  margin-top: 72px;
}

.banner-logo img {
  width: 38%;
  margin-bottom: 20px;
}

.banner-button:hover {
  color: #fff !important;
  background: #000;
}

.banner-contant h2 {
  font-size: 50px !important;
  font-family: "Lato", sans-serif;
  font-size: 60px;
  font-weight: 300;
  color: #555;
}

.banner-contant h2 span {
  color: #0086c9;
  font-weight: 700;
}

.light-blue {
  background: #3385d6;
  padding-top: 30px;
  padding-bottom: 30px;
}

.medium-blue {
  background: #1a76d1;
  padding-top: 30px;
  padding-bottom: 30px;
}

.dark-blue {
  background: #0368ca;
  padding-top: 30px;
  padding-bottom: 30px;
}

.three-part h3 {
  font-family: "Lato", sans-serif;
  font-size: 24px;
  color: #fff;
  margin: 6px 0 20px 0;
  font-weight: 300;
}

.three-part p {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  font-weight: 300;
}

.disruption-section {
  width: 100%;
  padding: 40px 0;
}

.title-part h2 {
  font-family: "Lato", sans-serif;
  font-size: 50px;
  color: #333;
  font-weight: 300;
}

.title-part p {
  font-family: "Lato", sans-serif;
  font-size: 24px;
  color: #333;
  margin-bottom: 80px;
  font-weight: 300;
}

.problem-to-solutions {
  max-width: 395px;
  margin: 0px auto;
}

.disruption-box-part {
  width: 50%;
  float: left;
}

.disruption-box {
  display: table;
  height: 240px;
  border: 0.5px solid #ccc;
  width: 100%;
  position: relative;
}

.inner-disruption {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 15px;
}

.disruption-box h3 {
  font-family: "Lato", sans-serif;
  font-size: 22px;
  color: #333;
  font-weight: 300;
}

.hover-disuption:hover {
  opacity: 1;
}

.hover-disuption {
  width: 100%;
  float: left;
  float: left;
  position: absolute;
  left: 0;
  padding: 15px;
  background: #fff none repeat scroll 0% 0%;
  right: 0;
  top: 0;
  bottom: 0;
  border: 0.5px solid #80b3e5;
  opacity: 0;
  transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
}

.disruption-box p {
  font-family: "Lato", sans-serif;
  font-size: 15px;
  color: #666;
}

.disruption-section .disuption-images-box .img-responsive.active {
  width: 100%;
  margin-top: 40px;
  opacity: 1;
}

.disruption-section .disuption-images-box .img-responsive {
  transition: all 0.7s;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  opacity: 0;
  width: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
}

.list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
}

.icon-bounce img {
  margin-bottom: 15px;
}

.icon-bounce li p {
  color: #555;
  font-family: "Helvetica LTStd light";
  font-size: 20px;
  line-height: 24px;
  padding: 0;
}

.icon-bounce li {
  width: calc((100% / 7) - 0.1px);
}

.icon-bounce li {
  width: calc((100% / 7) - 0.1px) !important;
}

.icon-bounce figure {
  min-height: 164px;
}

.icon-bounce li > *:not(span) {
  width: 100%;
}

.icon-bounce img {
  margin-bottom: 15px;
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.icon-bounce {
  padding-bottom: 20px;
}

.icon-bounce li {
  width: calc(100% / 7);
  padding: 0;
  float: left;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.icon-bounce li > *:not(span) {
  width: 100%;
}

.sn-dot span {
  font-size: 2em;
}

.fs-dot span {
  font-size: 2em;
}

.process {
  width: 100%;
  background: #424242;
  padding: 60px 0;
}

.process .title-part h2 {
  color: #fff;
  font-weight: 300;
}

.process .title-part p {
  color: #fff;
}

.optimize {
  max-width: 360px;
  margin: 0px auto;
}

.custom-artical {
  background: #727272 !important;
}

.gray-logo-image {
  width: 280px;
}

.artical-part {
  width: 100%;
  padding: 40px 0;
  background: #424242;
}

.artical-part p {
  font-size: 24px;
  color: #fff;
  font-weight: 300;
  font-family: "Lato", sans-serif;
  margin-top: 10px;
}

.artical-part h2 {
  font-size: 50px;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-weight: 300;
}

.artical-part a {
  font-size: 16px;
  color: #fff;
  background: #06c;
  padding: 10px 30px;
  border: 1px solid #06c;
  border-radius: 40px;
  margin-top: 30px;
  font-family: "Lato", sans-serif;
}

.artical-part a:hover {
  background: none;
  color: #fff;
}

.testimonials h2 {
  font-size: 50px;
  color: #333;
  font-weight: 300;
  font-family: "Lato", sans-serif;
  text-align: center;
}

.carousel {
  padding: 30px 0 0;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.testimonials-text {
  width: 94%;
  float: left;
  text-align: left;
  padding: 30px;
  border: 1px solid #e1e1e1;
  box-shadow: 0 0 10px #e1e1e1;
  min-height: 180px;
}

.testimonials-text span strong {
  font-weight: bold;
  color: #333;
  font-size: 16px;
}

.img-circle {
  border-radius: 50%;
}

.testimonials-text span {
  width: 90%;
  float: left;
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 300;
  color: #333;
}

.testimonials {
  width: 100%;
  padding: 40px 0;
}

.testimonials-text span.image-part {
  width: 10%;
  float: left;
  margin-top: 20px;
}

.Reduce {
  max-width: 600px;
  margin: 0px auto;
}

/* home page css start */

/* login css start */

.login-enter-section {
  margin-top: 150px;
}

.left-side-login {
  background: #f5f5f5 none repeat scroll 0 0;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 27px;
}

.left-side-login h2 {
  color: #444444;
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.left-side-login label {
  color: #444444;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.left-side-login input {
  border: 2px solid #dddddd;
  border-radius: 0;
  margin: 10px 0 0;
  height: calc(1.2em + 0.75rem + 2px);
}

.right-area-login h2 {
  font-size: 26px;
  margin: 0;
  color: #444444;
}

.right-area-login p {
  color: #888888;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 30px;
  padding: 40px 0 0px;
}

.btn.artical-button {
  background: #0066cc none repeat scroll 0 0;
  border: 1px solid #0066cc;
  border-radius: 40px !important;
  color: #fff;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  padding: 10px 30px;
}

.left-side-login p {
  color: #0066cc;
  padding: 40px 0 0;
  text-align: right;
  font-size: 16px;
}

.right-area-login label {
  padding: 0;
  padding-top: 15px;
  color: #444444;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.form-control.project-name {
  border: 2px solid #e6e6e6;
  box-shadow: none;
  height: 42px;
  max-width: 355px;
  width: 100%;
  border-radius: 0px;
}

.select-btn {
  height: 42px;
  max-width: 100%;
  width: 100%;
  border-radius: 0px;
  border: 2px solid #e6e6e6;
}

.right-area-login .btn.artical-button {
  border-radius: 40px;
  padding: 12px 55px;
}

/* login css end */

/* footer css start*/

footer {
  z-index: 9999;
  position: relative;
  background: #f3f3f3 none repeat scroll 0 0;
}

footer p {
  color: #666;
  font-size: 14px;
  font-family: "Helvetica LTStd light";
  font-weight: 100;
  margin-bottom: 5px;
}

.footer-bottom-nav-link a {
  margin-right: 10px;
}

footer p a {
  color: #666;
  font-size: 14px;
  font-family: "Helvetica LTStd light";
  font-weight: 100;
}

footer hr {
  width: 100%;
  float: left;
}

/* footer css end */

.docter-treatment {
  padding: 30px 0 0;
}

.docter-treatment h2 {
  padding-top: 20px;
  font-size: 40px;
  color: #333;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  margin-bottom: 0;
}

.docter-treatment p small {
  font-size: 16px;
  color: #999;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

.docter-treatment .carousel-item {
  min-height: 450px;
}

.docter-treatment p {
  font-size: 24px;
  color: #333;
  font-family: "Lato", sans-serif;
  font-weight: 300;
}

.disruption-section .disuption-images-box .img-fluid.active {
  width: 100%;
  margin-top: 40px;
  opacity: 1;
}

.disruption-section .disuption-images-box .img-fluid {
  transition: all 0.7s;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  opacity: 0;
  width: 0;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
}

/* contect us css start */

.about-us-banner,
.contact-us-banner,
.privacy-banner,
.terms-of-use-banner,
.site-map-banner {
  padding: 150px 0 200px;
  background-repeat: no-repeat;
}

.contact-us-banner {
  background-image: url("assets/images/contact-us-Hero-Image.jpg");
}

.banner-inner-title {
  padding-top: 0;
  vertical-align: middle;
}

.inner-heading {
  font-weight: 800 !important;
  font-size: 60px;
  text-align: left;
  color: #555;
}

.form-box .title-part h2 {
  font-size: 24px;
  font-weight: 700;
}

.form-box .title-part .desc {
  font-size: 18px;
  font-weight: 400;
  color: #949494;
}

.office-address-box .address {
  border-bottom: 1px solid #f4f6f7;
  margin-bottom: 30px;
}

.office-address-box h2 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 800;
  margin: 0;
  margin-bottom: 15px;
}

.office-address-box p {
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 20px;
}

.form-box a {
  font-size: 16px;
}

.form-box a {
  font-size: 16px;
}

.office-address-box .address {
  border-bottom: 1px solid #f4f6f7;
  margin-bottom: 30px;
}

.form-box .form-control {
  border-radius: 0;
  margin-bottom: 20px;
  padding: 25px 20px;
}

.form-box .form-control {
  font-size: 16px;
  color: #85898b;
}

.form-box .custom-submit {
  background: #06c;
  font-size: 24px;
  border-radius: 40px;
  padding: 10px 53px;
}

/* contect us css end */

.about-us-banner {
  background-image: url("assets/images/about-us-Hero-Image.jpg");
}

.privacy-banner {
  background-image: url("assets/images/about-us-Hero-Image.jpg");
}
