.demo:empty {
  margin: auto;
  width: 100%;
  height: 80vh; /* change height to see repeat-y behavior */

  background-image: radial-gradient(
      circle 30px at 50px 30px,
      lightgray 99%,
      transparent 0
    ),
    linear-gradient(100deg, #ffffff00, #ffffff80 50%, #ffffff00 80%),
    linear-gradient(lightgray 20px, transparent 0),
    linear-gradient(lightgray 20px, transparent 0),
    linear-gradient(lightgray 20px, transparent 0),
    linear-gradient(lightgray 20px, transparent 0);

  background-repeat: repeat-y;

  background-size: 100px 200px, 50px 200px, 150px 200px, 350px 200px, 100% 200px,
    100% 0px;

  background-position: 0 0, /* circle */ 0 0, /* highlight */ 120px 0,
    120px 40px, 120px 80px, 120px 120px;

  animation: shine 1.3s infinite;
}

@keyframes shine {
  to {
    background-position: 0 0, 100% 0, /* move highlight to right */ 120px 0,
      120px 40px, 120px 80px, 120px 120px;
  }
}
